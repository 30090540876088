.custom-parent-about-us {
  background-image: url('../../images/about_us/about_us_back.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}


.custom-parent-aboutt .custom-text-about {

  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: justify;
}

.custom-parent-aboutt .custom-card-about {
  background: #FFF3F2;
  border: none;
  position: relative;
  padding-top: 30px !important;
  min-height: 270px;
}

.custom-card-about .title-wrapper h3 {
  background: #ED2B24;
  display: inline-block;
  color: #ffffff;
  border-radius: 50px;
  font-family: Poppins;
  font-size: 19px;
  font-weight: 500;
  line-height: 34.5px;
  padding: 3px 10px;
  position: absolute;
  top: -20px;
}

.custom-parent-aboutt .whats-setup {
  background: #F5F5F5;

}


.whats-setup .icon-placeholder {
  background: #FBAA19;
  border-radius: 50%;
  padding: 6px;
}

.whats-setup .icon-placeholder img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  display: block;
  text-align: center;
}

.whats-setup h3 {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  text-align: left;
  color: #ED2B24;
}

.whats-setup p {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  text-align: justify;
  color: #000000;
}

/* @keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.welcome-about-content1 {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.welcome-about-content1.animate {
  animation: fadeInUp 0.5s ease-in-out forwards;
} */


.custom-parent-aboutt .join-us-content{
  background: #FFF3F2;

}


@media (min-width: 320px) and (max-width: 767px) {
  .custom-parent-aboutt .about-container .about-title {
    font-size: 21px;
    line-height: 18px;
  }

  .custom-parent-aboutt .about-container .about-title img{
    width: 30px;
    height: 30px;
    object-fit: contain;
  }

  .about-container .welcome-title {
    font-size: 18px;
    line-height: 20.5px;
}

.about-description {
  font-size: 15px;
}

.custom-card-about .title-wrapper h3,.whats-setup h3 {
  
  font-size: 16px;
}

.custom-parent-aboutt .custom-text-about,.whats-setup p {
  font-size: 15px;
}

}