.product-box {
  width: 100%;
  height: 200px;
  border: 1px solid #222222;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.product-box:hover {
  border: 2px solid #FFA200;
}

.image-wrapper {
  width: 90%;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  right: -2px;
  background-color: #FEFFFF;
}

.image-wrapper img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.product-parent {
  background: #FFF6E8;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.product-title {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #000000;
  text-align: center;
}

.product-child:hover .product-title{
  color: #FFA200
}

.load-more-btn {
  width: 181px;
  height: 45px;
  border-radius: 50px;
  background-color: #ED2B24;
  color: white;
  font-weight: bold;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.load-more-btn:hover {
  background-color: #ffffff;
  color: black;
  border: 1px solid black;
}

.welcomeabout-container{
  position: relative;
  overflow: hidden;
}

.title-product,
.about-title {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
}

.welcome-title {
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  text-align: center;
  color: #000000;
}

.about-description {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 30px;
  text-align: justify;
  color: #000000;
}


.welcome-about-content .c-rocket-image .c-child-img{
  width: 400px;
  height: 400px;
}

.welcomeabout-container img.about-bg1-img {
  position: absolute;
  top: 30%;
  left: 0;
  width: 350px;
  height: 350px;
  object-fit: contain;
  z-index: 99;
}

.welcomeabout-container img.about-bg-img {
  position: absolute;
  bottom: 5%;
  right: 0;
  width: 350px;
  height: 350px;
  object-fit: contain;
  z-index: 99;
}



.manufacturer-section {
  width: 100%;
  height: 100%;
  /* background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; */
}

.manufacturer-section .cust-slider-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-slider, .slick-list {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}


.manufacturer-section slick-slide img {
  width: 100%;
  height: auto;
  display: block;
}

.manufacturer-section slick-slide img {
  z-index: 10;
}

.custom-video-section .slick-prev::before,
.custom-video-section .slick-next::before {
  display: none; 
}

.custom-video-section .slick-prev {
  background: #A20000!important; 
  width: 25px!important; 
  height: 25px!important;
  transition: background-color 0.3s ease; 
  left: 2px;
}

.custom-video-section .slick-next {
  background: #EEEEEE!important; 
  width: 25px!important; 
  height: 25px!important;
  transition: background-color 0.3s ease; 
  right: 2px;
}





.manufacturer-child {
  position: absolute;
  background-image: url('../images/home/rectangle.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.star1 {
  position: absolute;
  top: 40px;
  right: 180px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;

}

.star2 {
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;


}

.star3 {
  position: absolute;
  bottom: 120px;
  left: 90px;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
}

.star4 {
  position: absolute;
  top: 50px;
  left: 0;
  width: 40px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
}



.manufacturer-title {
  font-family: 'Poppins', sans-serif;
  font-size: 52px;
  font-weight: 700;
  color: white;
  line-height: 70px;
  text-align: left;
}

.view-products-btn {
  width: 200px;
  height: 50px;
  background-color: white;
  color: #000;
  font-weight: 500;
  font-size: 17px;
  border-radius: 25px;
  margin-top: 20px;
  display: inline-block;
}

.view-products-btn:hover {
  background-color: white;
  color: black;
}

.image-section {
  position: relative;
  /* max-width: 50%; */
  text-align: left;
}

.rectangle-image {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 80%;
}

/* .hero-img {
    width: 50%;
    height: 502px;
    object-fit: cover;
  } */

.upbg-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text-section {
  margin-left: 120px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Hero Section Animation */

.manufacturer-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.manufacturer-section {
  animation: fadeInUp 0.5s ease-in-out forwards;
}


/* Welcome About of Home animation */

.welcome-about-content {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.welcome-about-content.animate {
  animation: fadeInUp 0.5s ease-in-out forwards;
}


/* Product animation  */

.product-child {
  position: relative;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.product-child.animate {
  animation: fadeInUp 0.5s ease-in-out forwards;
}

.product-box {
  animation: fadeIn 0.5s ease-in-out;
}

.product-image {
  transition: transform 0.3s ease-in-out;
}

.product-box:hover .product-image {
  transform: scale(1.1);
}

.loader-image {
  transition: transform 0.3s ease-in-out;
}

.loader-image.rotate {
  animation: rotate 0.3s linear;
}

/* .video-slide {
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  max-height: 180px; 
  height: 100%; 
  box-sizing: border-box;
} */

/* .video-player {
  width: 100%;
  max-height: 150px; 
  height: 100%; 
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */


.custom-modal {
  width: 60%; 
  max-width: 800px; 
  height: 40vh; 
  max-height: 40vh; 
  border-radius: 12px; 
  overflow: hidden; 
}

.video-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black; 
}

.video-player {
  max-width: 60%;
  max-height: 100%; 
  object-fit: contain; 
}

.video-slide{
  padding: 0 10px;
}





@media (min-width: 769px) and (max-width: 821px) {
  .video-player {
    /* max-height: 118px; */
    height: 100%;
  }
}


@media (min-width: 320px) and (max-width: 767px) {

  .modal-dialog.custom-modal{
    margin: 0 auto;
  }

  .video-player {
    max-width: 90%;
  }

  .custom-video-section .c-text-video{
    font-size: 24px;
    line-height: 20px;
  }

  .title-product,
  .about-title {
    font-size: 31px;
    line-height: 20px;
  }

  .welcome-title {
    font-size: 21px;
    line-height: 20.5px;

  }


  .text-section {
    margin-left: 0;
  }

  .manufacturer-title {
    font-size: 30px;
    line-height: 52px;
  }

  .upbg-image {
    width: 80%;
    height: 80%;
  }

  .view-products-btn {
    font-size: 15px;
    width: 170px;
    height: 40px;
  }

  .manufacturer-child {
    background-size: 90%;
  }

  .star1 {
    right: 48px;
    width: 70px;
    height: 70px;
  }

  .star4 {
    left: 19px;
    width: 40px;
    height: 100px;
  }

  .star3 {
    bottom: 22px;
    left: 32px;
    width: 60px;
    height: 60px;
  }

  .about-description {
    font-size: 15px;
  }

  .welcomeabout-container img.about-bg1-img {
    display: none;
  }

  .welcome-about-content .c-rocket-image .c-child-img {
    height: unset;
}
  

}