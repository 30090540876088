@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
    /* font-family: "Playfair Display", serif; */
    font-family: "Poppins", sans-serif;

  font-optical-sizing: auto;
  font-style: normal;
}
.brandName{
    color: blue;
    text-shadow: 4px 0px 1px white;
}
.nav-link{

}

.accordion-button::after{
    height: 0;
}
.accordion-button{
    position: relative;
    padding:0;
}
.accordion-button div{
    position: relative;
    padding: 15px;
    padding-right: 100px;
}
.accordion-body div div{
    border: 1px solid rgba(207, 207, 207, 0.741);
    border-collapse: collapse;
    align-items: center;
}
