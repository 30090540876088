.custom-footer-parent {
    position: relative;
    padding: 20px 0;
    background-color: #D9140D;
}

.footer-img-left {
    position: absolute;
    top: 40px;
    left: 0;
    width: 120px;
    height: auto;
    filter: brightness(0) invert(1);
}

.footer-img-right {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 180px;
    height: auto;
    filter: brightness(0) invert(1);
}


.custom-footer-parent .footer-text-link {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #FFFFFF;
}

.custom-footer-parent .footer-text-link:hover {
    color: yellow;
}

.c-arrow-icon{
    filter: brightness(0) invert(1);
}

.custom-footer-parent .cust-contact-us p:hover {
    cursor: pointer;
}

.custom-footer-parent .footer-heading {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: #FFFFFF;
}

.custom-footer-parent .footer-logo {
    width: 80%;
    height: 80%;
}

.copyright-section {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
    background-color: #f8f9fa;
}

.copyright-section a.innovative-link {
    text-decoration: none;
    font-weight: 500;
    color: #dc3545;
}
 
.copyright-section a.innovative-link:hover {
    color: red; 
    text-decoration: underline; 
}


.custom-footer-parent {
    opacity: 0;
    transition: opacity 1s ease-out;
}

.custom-footer-parent.animate {
    opacity: 1;
    animation: fadeInUp 1s ease-out forwards;
}




@media (min-width: 320px) and (max-width: 767px) {
    .copyright-section {
        font-size: 12px;
    }

    .copyright-section span {
        text-align: center;
    }

    .footer-img-right {
        display: none;
    }

}