.custom-product-page .product-section {
    background: #25002A;
    width: 100%;
    height: 300px; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .custom-product-page .products-text {
    font-family: 'Poppins', sans-serif;
    font-size: 45px;
    font-weight: 500;
    color: #FFFFFF;
    z-index: 2;
    position: relative;
    text-align: center;
  }
  
  /* .custom-product-page .cracker-design {
    width: 100px; 
    height: auto;
    top: 20%; 
  } */
  
  .custom-product-page .cracker {
    width: 120px;
    max-width: 100%;
    /* top: 10%; */
  }
  
  .custom-product-page .cracker-left {
    left: 0;
  }
  
  .custom-product-page .cracker-left-img {
    left: 10%;
  }
  
  .custom-product-page .cracker-right {
    right: 0;
  }
  
  .custom-product-page .cracker-right-img {
    right: 10%;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0); 
    }
    40% {
      transform: translateY(-15px); 
    }
    60% {
      transform: translateY(-8px); 
    }
  }
  
  .notice-container {
    animation: bounce 2s infinite; 
    background-color: #ED2B24;
    color: #F9F800;
    border-radius: 50px;
    padding: 10px 20px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    display: inline-block;
    text-align: center;
    margin: 50px 0;
  }


      
  

  @media (min-width: 320px) and (max-width: 767px) {
    .custom-product-page .products-text {
        font-size: 30px;
      }
    
    .notice-container {
      border-radius: 20px;
      padding: 20px;
      font-size: 14px;
      line-height: 25px;
      /* margin: 40px 20px; */
    }
    

  }