header.custom-header nav .header-f{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
}  

header.custom-header .nav-link {
    position: relative;
    padding-bottom: 5px;
}

header.custom-header .nav-link:hover::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px; 
    background-color: #ED2B24; 
    width: 100%; 
}

.custom-nav .navbar-nav .nav-link {
    font-size: 1.2rem;
  }

.custom-phone span{
    font-size: 1.2rem;
}
  
  