.custom-child-contact-us {
  padding: 60px 0;
}

.custom-contact-us {
  background-image: url('../../images/contactus/contact-banner.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 252px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-contact-us h1 {
  font-family: Poppins;
  font-size: 45px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
}

.custom-bt-contact {
  background: #ED2B24;
  border-radius: 50px;
  padding: 5px 20px;
}

.custom-our-address h4 {
  color: #ED2B24;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  border-bottom: 2px solid #ED2B24;
  display: inline-block;
}

.custom-our-address p {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #1E1E1E;

}

.custom-parent-contact-us .contact-us-para {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #000000;

}

.custom-parent-contact-us .contact-us-heading {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 500;
  line-height: 37.5px;
  text-align: left;
  color: #000000;
  border-bottom: 2px solid #dc3545;
  display: inline-block;

}

.input-field {
  border: 0.3px solid #4E4E4E;
  border-radius: 4px;
  padding: 12px 16px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  position: relative;
}

.custom-textarea textarea {
  border: 0.3px solid #4E4E4E;
  resize: none;
  font-family: Poppins, sans-serif;
  background-color: #fff;
  box-sizing: border-box;
}

.floating-label {
  position: absolute;
  top: 12px;
  left: 16px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #6c757d;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.input-field:focus {
  outline: none;
  border-color: #dc3545;
}

.floating-label {
  position: absolute;
  left: 16px;
  top: 12px;
  font-size: 18px;
  color: #6c757d;
  transition: 0.3s ease-in-out;
  pointer-events: none;
}

.input-field:focus + .floating-label,
.input-field:not(:placeholder-shown) + .floating-label {
  top: -17px;
  left: 12px;
  font-size: 14px;
  color: #dc3545;
}


.address-heading {
  border-bottom: 2px solid #dc3545;
  display: inline-block;
  padding-bottom: 4px;
  margin-bottom: 12px;
}

.map-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


@media (min-width: 320px) and (max-width: 767px) {
  .custom-child-contact-us {
    padding: 20px 0;
  }

  .custom-contact-us {
    height: 98px;
  }

  .custom-contact-us h1 {
    font-size: 30px;
  }

}