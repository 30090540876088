.custom-table-parent {
  position: relative;
  overflow: visible;
}

.custom-table-parent .custom-position {
  position: relative;
}

table.custom-table-product {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 2px solid #ED2B24;
}

table.custom-table-product th,
table.custom-table-product td {
  padding: 8px;
  border: 1px solid #A1A1A1;
  text-align: center;
}

table.custom-table-product th {
  background-color: #ff0000;
  color: white;
  border-right: 1px solid #FFFFFF;
}

table.custom-table-product tr:nth-child(odd) {
  background-color: white;
}

table.custom-table-product tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.custom-table-product tr:hover {
  background-color: #ddd;
}

table.custom-table-product td img {
  display: block;
  margin: auto;
}

table.custom-table-product th {
  border-right: 2px solid #fff;
}

.section-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 20px;
  color: #ED2B24;
}

.custom-table-parent .c-total-header span {
  color: #ED2B24;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.custom-table-parent .c-total-header h5 {
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.custom-table-parent .fixed-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #F9F9F9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-table-parent .fixed-header1 {
  position: sticky;
  top: 110px;
  width: 100%;
  z-index: 1000;
  background-color: #F9F9F9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.custom-product-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 5px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-product-image:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.custom-modal .modal-header .btn-close {
  margin: 0; 
}

.custom-accordion-header .accordion-button {
  color: #ED2B24; 
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 19px;
  border: none;
}

.custom-accordion-header .accordion-button:after {
  font-size: 20px;
}

.custom-tr-product .qty-input {
  width: 100%; 
  text-align: center;
}

@media (min-width: 320px) and (max-width: 767px) {
  .custom-tr-product .qty-input {
    width: 100px!important; 
  }

  .custom-table-parent .c-total-header span{
    font-size: 16px;
    line-height: 22px;
  }

  .custom-table-parent .c-total-header h5{
    font-size: 16px;
    line-height: 22px;
  }
  
  .custom-table-parent .fixed-header1 {
    top: 90px;
  }

}
